import {FAVORITE_URL} from "../costants/Urls";
import axios from 'axios';
import {ADD_FAVORITE, SET_STATE} from "../costants/ActionTypes";


export const setFavoriteState = status => ({
    type: SET_STATE,
    status
});

export const updateFavorite = data => ({
    type: ADD_FAVORITE,
    data
});

const post_config = {
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    withCredentials: true
}


export const addToFavorite = (data) =>  (dispatch, getState) => {

  const url = FAVORITE_URL;
  const loading_state = getState().favbutton.status;

  if ((loading_state==="NOT_LOADED")||(loading_state==="READY")){

      dispatch(setFavoriteState('FETCHING'));

      axios.post(url, data, post_config).then( res => {
         if (res.status === 200 || res.status === 201){
             dispatch(updateFavorite({infavorite: true, count: 1}));
             dispatch(setFavoriteState('READY'));

             let updateEvent = new CustomEvent("FavoriteNeedUpdate", {detail: "addtofavorite"});
             document.dispatchEvent(updateEvent);

         }else{
             dispatch(setFavoriteState('ERROR'));

         }
      }).catch(err=> {
          dispatch(setFavoriteState('ERROR'));
      })
  };
};
