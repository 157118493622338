import {
    SET_STATE,
    ADD_FAVORITE
} from '../costants/ActionTypes'

const initialState = {
  status: "NOT_LOADED",
  infavorite: false,
  count: 0
}


const favbutton = (state = initialState, action) => {

    switch (action.type) {

        case SET_STATE:
            return Object.assign({}, state, {
                status: action.status
            })

        case ADD_FAVORITE:
            return Object.assign({}, state, action.data)

        default:
            return state

  }
}

export default favbutton