import React from 'react';
import {render} from 'react-dom';
import AddProductButton from './components/FavButton';
import FavLink from "./components/FavLink";
import {default as configureButtonStore} from "./store/configureStore";

document.addEventListener("DOMContentLoaded", function(event) {
  const addProductButtonContainerList = document.querySelectorAll('.js-add-fav');
  const starsFavoriteList = document.querySelectorAll('.js-list-fav');

  if((addProductButtonContainerList.length > 0)|| (starsFavoriteList.length > 0)){
    const buttonStore = configureButtonStore();

    starsFavoriteList.forEach(function(el, index){
      let LinkAttrs = el.attributes;
      render(<FavLink store={buttonStore} attrs={LinkAttrs} key={index}/>, el);
    });

    addProductButtonContainerList.forEach(function(el, index){
      let buttonAttrs = el.attributes;
      render(<AddProductButton store={buttonStore} attrs={buttonAttrs} key={index}/>, el);
    });
  }
});

// enable hot module relaoding for the dev server
if (process.env.NODE_ENV === 'development')
  module.hot.accept();
