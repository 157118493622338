import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {addToFavorite} from '../actions/FavButtonActions'


const FavButton = props => {

  const {attrs, status, infavorite, addToFavorite} = props
  const product_id = attrs.getNamedItem('data-product-id').value
  // small, big
  const button_size = attrs.getNamedItem('data-size') ? attrs.getNamedItem('data-size').value : "small"

  // useEffect(() => {}, []);

  const handleAddFavorite = (e) =>{

    if(!infavorite){
      const pdata = {
        "app": "product",
        "id": product_id,
        "quantity": 1,
        "data": {}
      }
      addToFavorite(pdata);
    }
    e.preventDefault();
  }
  return (
      <>
      {button_size === "small" ?
          <div className={'position-relative'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                 className={infavorite ? "bi bi-bookmark-star text-danger" : "bi bi-bookmark-star"} viewBox="0 0 16 16"
                 onClick={e => handleAddFavorite(e)}>
              <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.178.178 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.178.178 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.178.178 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.178.178 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.178.178 0 0 0 .134-.098L7.84 4.1z"/>
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
            </svg>
            {infavorite ?
                <span
                    className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                      <span className="visually-hidden">В избранном</span>
                </span>
                :
                null
            }
          </div>
          :
          <button className={'btn btn-warning shadow-sm position-relative w-100 my-2 py-2'}
            onClick={e => handleAddFavorite(e)}>
            Добавить в избранное
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                 className="bi bi-bookmark-star pull-right" viewBox="0 0 16 16">
              <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.178.178 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.178.178 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.178.178 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.178.178 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.178.178 0 0 0 .134-.098L7.84 4.1z"></path>
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"></path>
            </svg>
            { infavorite ?
              <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                  <span className="visually-hidden">В избранном</span>
              </span>
                :
                null
            }
          </button>
      }
      </>
  )
};


const mapStateToProps = state => ({
  status: state.favbutton.status,
  infavorite: state.favbutton.infavorite
});

const mapDispatchToProps = dispatch => bindActionCreators({addToFavorite}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FavButton);